const api = '/api';
const ctp = '/ctp';
export default {
  login: api + '/login',
  configList: api + '/config/list',  // 查询测试项
  startQa: api + '/template/startQa',  // 开始测试
  queryList: api + '/history',   // 查询记录
  removeSn: api + '/Remove/Lock',  // 删除锁号
  insertHistory: api + '/history/insert',  // 插入历史数据
  addSetting: api + '/configuration/add_facility', // 添加设置IMEI信息
  checkSettingSuccess: api + '/configuration/lockInitResult',  // 根据cmdid查看是否IMEI配置成功
  saveSetting: api + '/configuration/add',  // 把成功的接口保存在数据库
  getSettingHistory: api + '/configuration/select',  // 查询设置IMEI的历史纪录
  getServiceList: api + '/configuration/serviceList', // 获取服务列表
  dealDirection: api + '/template/getCMD', // 处理下发指令

  // 调用车场相关的接口
  checkParkDeviceIsset: ctp + '/worker-app-api/device-isset', // 查询当前锁号是否在车场存在过
  addParkDevice: ctp + '/worker-app-api/device-add', // 将锁号添加到列表中
  removeParkDevice: ctp + '/worker-app-api/device-del', // 将锁号删除

  // 绑定配件
  bindParts: ctp + '/client-hand2-api/berthlock-qa-bind',
  getPartsInfo: ctp + '/client-hand2-api/get-part-apply',
  applyParts: ctp + '/client-hand2-api/berthlock-part-apply',
  //搜索获取收货人信息
  getAddresseeInfo: ctp + '/client-hand2-api/berthlock-lot-search'
}
